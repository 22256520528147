<template>
  <div class="order">
    <titleNav title="订单详情" :left-arrow="true" left-text="" />
    <div class="topbacc">
      <div>
        {{
          datas.status == 1
            ? "未支付"
            : datas.status == 2
            ? "已支付"
            : datas.status == 4
            ? "已退款"
            : "已办结"
        }}
      </div>
    </div>
    <div class="icon">
      <div><van-icon name="location-o" />石家庄</div>
      <div>{{ year }}年灵活就业养老办理</div>
      <div>￥{{ datas.payble_money }}</div>
    </div>
    <div class="information">
      <div><van-icon name="balance-list-o" />订单基本信息</div>
    </div>
    <div class="infor">
      <div>订单号编号</div>
      <div style="font-size: 14px">{{ datas.id }}</div>
    </div>
    <div class="infor">
      <div>创建时间</div>
      <div>{{ datas.time }}</div>
    </div>
    <div class="infor" v-if="datas.pay_time != ''">
      <div>支付时间</div>
      <div>{{ datas.pay_time }}</div>
    </div>
    <div class="infor" style="">
      <div>业务类型</div>
      <div>{{ datas.order_type == 1 ? "养老业务 " : "养老医疗" }}</div>
    </div>
    <div class="infor">
      <div>缴费信息</div>
      <div>{{ datas.true_name }}({{datas.true_num}})</div>
      <div style="width: 90vw;">{{ datas.phone}}</div>
    </div>
    <button v-if="datas.status==1" @click="details(datas.id)">继续</button>
    <button v-else @click="details(datas.id)">完成</button>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import { Icon } from "vant";
import { details } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Icon.name]: Icon,
  },
  data() {
    return {
      datas: {},
      year: "",
    };
  },
  mounted() {
    this.time();
    this.list();
  },
  methods: {
    time() {
      var myDate = new Date();
      this.year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    },
    list() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        id: this.$route.query.id,
      };
      details(data).then((res) => {
        this.datas = res.data.data;
        this.datas.true_num = this.datas.true_num.replace(
          /^(.{6})(?:\d+)(.{4})$/,
          "$1********$2"
        );
      });
    },
    details(e) {
      let id = e;
      if(this.datas.status==1){
          this.$router.push("/qianming?data=" + id + "&identification=" + 1);
      }else{
        //   let url=sessionStorage.getItem("urls");
        //   let urls=url.substring(24, 79);
        //   this.$router.push("/"+urls);
          this. $router.go(-3)
      }
      
    },
  },
};
</script>

<style scoped lang="scss">
.order {
  .topbacc {
    padding-left: 4vw;
    padding-top: 25px;
    width: 100%;
    height: 100px;
    background-color: #0f81fd;
    color: #ffffff;
    font-size: 22px;
    line-height: 30px;
    font-weight: 900;
  }
  .icon {
    margin: 15px;
    font-size: 20px;
    height: 70px;
    div:nth-child(2) {
      float: left;
      line-height: 40px;
    }
    div:nth-child(3) {
      float: right;
      color: red;
      margin-top: -15px;
    }
  }
  .information {
    font-size: 20px;
    margin: 15px;
  }
  .infor {
      
    margin: 15px;
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    div:nth-child(1) {
      float: left;
    }
    div:nth-child(2) {
      float: right;
    }
    div:nth-child(3) {
      float: right;
      text-align: right;
    }
  }
  button{
      position: fixed;
      bottom: 10px;
      left: 0;
      background-color: #0f81fd;
      color: #ffffff;
      border: 0;
      width: 92vw;
      margin-left: 4vw;
      font-size: 22px;
      height: 50px;
      border-radius: 5px;
  }
}
</style>